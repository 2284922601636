<template>
    <div class="card">
        <div class="left tab-bar">
            <div class="clickable tab" @click="active = '高速天气'" :class="active === '高速天气' ? 'active' : ''">
                高速天气
            </div>
            <div class="clickable tab" @click="active = 'cloud'" :class="active === 'cloud' ? 'active' : ''">
                机场天气
            </div>
        </div>

        <div class="body">
            <div id="map"></div>
            <map-alert :title="selected.stationname" :visible="selectedVisible" @close="selectedVisible = false">
                <div>
                    <!-- <div class="tab-bar">
                        <div class="tab " :class="categoryActive === '气温' ? 'active' : ''"
                            @click="categoryActive = '气温'">气温</div>
                    </div> -->
              
                    <Temp  :list="weather" />

                </div>


            </map-alert>
        </div>

    </div>

</template>

<script>
import MapAlert from '@/components/map-alert'
import air from './air'
import road from './road'
import airMarkerImage from '@/assets/icons/air.png'
import carMarkerImage from '@/assets/icons/car.png'
import { fetchWeatherByLnglat } from '@/api/weather'
import Temp  from './temp.vue'
export default {
    components: {
        MapAlert,
        Temp,
    },
    data() {
        return {
            active: '',
            markers: [],
            selected: {}, // 选中的收费站或机场
            selectedVisible: false, // 弹窗显示隐藏
            categoryActive: '气温', // 弹窗中的分类选中
            weather: [], // 根据经纬度查出的天气情况
        }
    },

    watch: {
        active() {
            let list = []
            let image = carMarkerImage  
            if (this.active === '高速天气') {
                list = road
            } else {
                list = air;
                image = airMarkerImage;
            }

            this.clearMarkerClickEvent();
            const { T } = window;
            if (!T) return;
            
            var icon = new T.Icon({
                iconUrl: image,
                iconSize: new T.Point(32, 32),
            });


            this.markers = list.map(item => {
                const marker = new T.Marker(new T.LngLat(item.longitude, item.latitude), { icon: icon });
                marker.addEventListener("click", this.onMarkerClick);
                this.map?.addOverLay(marker);

                return marker;
            })

       

        }
   },

    mounted() {
        this.initmap();
        this.active = '高速天气'
    },

    destroyed() {
        this.clearMarkerClickEvent();
        // this.map?.destroy();
    },

    methods: {
        initmap() {
            const { T } = window;
            if (!T) return;
               const map = new T.Map('map');
            map.centerAndZoom(new T.LngLat(119.14268709684, 33.617462694202), 8);
            const control = new T.Control.Zoom();
            map.addControl(control);
            this.map = map;
        },

        clearMarkerClickEvent() {
                             if (this.markers && this.markers.length > 0) {
                this.markers.forEach(marker => {
                    marker.removeEventListener("click", this.onMarkerClick);
                })
                this.map?.clearOverLays();
            }
        },

        onMarkerClick(ev) {
                console.log('ev',ev)
                this.selectedVisible = true;

                fetchWeatherByLnglat({
                    lng: ev.lnglat.lng,
                    lat: ev.lnglat.lat
                }).then(res => {
                    console.log('res', res)
                    if (res && Array.isArray(res.data)) {
                        this.weather = res.data;
                    }
                })

    },

    },

}
</script>

<style lang="less" scoped>
.card {
    background-color: #fff;

    & > .tab-bar {
        .tab {
            padding: 12px 24px;
            text-align: center;
        }
        .active {
            color: var(--theme-color);
            font-weight: bold;
            border-bottom: 2px solid var(--theme-color);
        }
    }

    .body {
        padding: 12px;
        position: relative;

        .tab-bar {
    display: flex;
    align-items: center;
    .tab {
        flex: 1;
        background-color: #77bcb9;
        color: #fff;
        padding: 8px;
        text-align: center;
        cursor: pointer;
    }
    .active {
        background-color: #fff;
        color: var(--theme-color);
    }
}
    } 

}


#map {
    height: 400px;
}

.body {

}

</style>