<template>
    <div class="container">
        <div class="panel">
            <div class="full">
                <Weather />
            </div>
            <div class="full">
        <img style="width: 100%" src="../../assets/app2.png" alt="">
            </div>

        </div>
    </div>
</template>

<script>
import Weather from './components/weather.vue'

import { fetchList } from '@/api/article'
export default {
    components: {
        Weather,
    },

    data() {
        return {
            list: []
        }
    },

    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            fetchList({
                p: [{
                    category: "jtqx",
                    page: 1,
                    rows: 10,
                    // face: 1
                },
                ]
            }).then(res => {
                if (Array.isArray(res) && res.length > 0) {
                    if (res[0].data && Array.isArray(res[0].data.list)) {
                        this.list = res[0].data.list;
                    }
                }
            })
        }
    }

}
</script>

<style lang="less" scoped>


</style>