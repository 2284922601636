<template>
  <div class="card">
    <div class="between normal-font" style="margin-top: 12px">
      <div v-for="(item, index) in list" :key="index">
        <div>
          {{ typeof item.date === "string" ? item.date.substring(5) : "日期" }}
        </div>
      </div>
    </div>
    <div class="between" style="margin-top: 8px">
      <div v-for="(item, index) in list" :key="index">
        <div>
          {{getDayWind(item)}}
        </div>
        <img :src="`/static/common/img/meteor/weather/daynight/day_${item.wea_day_img}.png`" alt="">
      </div>
    </div>
    <div id="temp-line-chart"></div>
    <div class="between">
      <div v-for="(item, index) in list" :key="index">
        <img :src="`/static/common/img/meteor/weather/daynight/night_${item.wea_night_img}.png`"
          alt="">
        <div style="padding-bottom: 12px">{{getNightWind(item)}}</div>
      </div>

    </div>
  </div>
</template>


<script>
import * as echarts from "echarts/core";
import { GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([GridComponent, LineChart, CanvasRenderer]);

export default {
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },

    watch: {
      list() {
        if (this.list.length > 0) {
          this.initChart(this.list);
          }
        }
    },

  mounted() {

    if (this.list.length > 0 ) {
      this.initChart(this.list)
    }
  },

  methods: {

    getDayWind(item) {
      let win = '';
      let speed = ''
      if (Array.isArray(item.win) && item.win.length > 0) {
          win = item.win[0]
      }
      if (typeof item.win_speed === 'string'
      ) {
        const arr = item.win_speed.split('转')
        if (arr.length > 0) {
          speed = arr[0]
        }
      }
      return win + speed;
    },

    getNightWind(item) {
      let win = '';
      let speed = ''
      if (Array.isArray(item.win) && item.win.length > 1) {
        win = item.win[1]
      }
      if (typeof item.win_speed === 'string'
      ) {
        const arr = item.win_speed.split('转')
        if (arr.length > 1) {
          speed = arr[1]
        } else if (arr.length > 0) {
          speed = arr[0]
        }
      }
      return win + speed;
    },

    initChart(list = []) {
      var chartDom = document.getElementById("temp-line-chart");
      if (!this.chart && chartDom) {
        this.chart = echarts.init(chartDom);
      }

      console.log('this.list', this.list)
      var option;

      option = {
        color: ['#238b88', '#e44e35'],
        xAxis: {
          show: false,
          boundaryGap: false,
          axisLine: {
            show: true,
            lineStyle: {
              // color: "transparent",
            },
          },

          splitLine: {
            lineStyle: {
              width: 0,
            },
          },
          type: "category",
          data: list.map((item) => {
            return {
              value: item.week,
              textStyle: {
                // color: "#fff",
              },
            };
          }),
        },
        grid: {
          left: "10%",
          right: "10%",
          top: "20%",
          bottom: 0,
        },
        yAxis: {
          show: false,
          splitLine: {
            show: false,
            lineStyle: {
              width: 0,
            },
          },
        },
        series: [
          {
            data: list.map((item) => parseFloat( item.tem1)),
            type: "line",
            smooth: true,
            label: {
              show: true,
              color: "#999",
              formatter: `{c}°`,
            },
          },
          {
            data: list.map((item) => parseFloat(item.tem2)),
            type: "line",
            smooth: true,
            label: {
              show: true,
              color: "#999",
              formatter: `{c}°`,
              position: 'bottom'
            },
          },
        ],
      };

      option &&
        this.chart &&
        this.chart.setOption &&
        this.chart.setOption(option);
    },

  },

};
</script>

<style lang="less" scoped>




  .between {
    padding-left: 4%;
    padding-right: 4%;

    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    img {
      width: 48px;
    }
  }

  #weather {
    height: 24px
  }

            #temp-line-chart {
              height: 80px;
            }
</style>