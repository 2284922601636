export default [
    {
        "longitude": 119.92555,
        "latitude": 32.34287,
        "city": "NULL",
        "stationname": "泰州收费站",
        "stationnumber": "GSLJS000042"
    },
    {
        "longitude": 119.619484,
        "latitude": 32.4337296,
        "city": "NULL",
        "stationname": "江都收费站",
        "stationnumber": "GSLJS000041"
    },
    {
        "longitude": 119.6107292,
        "latitude": 32.0339827,
        "city": "NULL",
        "stationname": "丹阳收费站",
        "stationnumber": "GSLJS000016"
    },
    {
        "longitude": 119.07154,
        "latitude": 31.67063,
        "city": "NULL",
        "stationname": "溧水东收费站",
        "stationnumber": "GSLJS000011"
    },
    {
        "longitude": 119.76286,
        "latitude": 34.10721,
        "city": "NULL",
        "stationname": "六套收费站",
        "stationnumber": "GSLJS000061"
    },
    {
        "longitude": 120.29498,
        "latitude": 33.36398,
        "city": "NULL",
        "stationname": "盐城开发区收费站",
        "stationnumber": "GSLJS000064"
    },
    {
        "longitude": 118.75221,
        "latitude": 34.18552,
        "city": "NULL",
        "stationname": "沭阳南收费站",
        "stationnumber": "GSLJS000037"
    },
    {
        "longitude": 119.07154,
        "latitude": 31.67063,
        "city": "NULL",
        "stationname": "溧水东收费站",
        "stationnumber": "GSLJS000011"
    },
    {
        "longitude": 119.00095,
        "latitude": 31.81708,
        "city": "NULL",
        "stationname": "句容西收费站",
        "stationnumber": "GSLJS000010"
    },
    {
        "longitude": 120.17109,
        "latitude": 33.64364,
        "city": "NULL",
        "stationname": "兴桥收费站",
        "stationnumber": "GSLJS000063"
    },
    {
        "longitude": 119.58338,
        "latitude": 34.25492,
        "city": "NULL",
        "stationname": "灌南东收费站",
        "stationnumber": "GSLJS000060"
    },
    {
        "longitude": 118.45299,
        "latitude": 34.36117,
        "city": "NULL",
        "stationname": "新沂东收费站",
        "stationnumber": "GSLJS000036"
    },
    {
        "longitude": 119.37861,
        "latitude": 33.24049,
        "city": "NULL",
        "stationname": "宝应收费站",
        "stationnumber": "GSLJS000039"
    },
    {
        "longitude": 119.4,
        "latitude": 31.4,
        "city": "NULL",
        "stationname": "宜兴收费站",
        "stationnumber": "GSLJS000013"
    },
    {
        "longitude": 119.4264507,
        "latitude": 31.4210467,
        "city": "NULL",
        "stationname": "溧阳西收费站",
        "stationnumber": "GSLJS000012"
    },
    {
        "longitude": 119.58338,
        "latitude": 34.25492,
        "city": "NULL",
        "stationname": "灌南东收费站",
        "stationnumber": "GSLJS000060"
    },
    {
        "longitude": 119.27633,
        "latitude": 34.41426,
        "city": "NULL",
        "stationname": "灌云北收费站",
        "stationnumber": "GSLJS000059"
    },
    {
        "longitude": 119.17867,
        "latitude": 33.58907,
        "city": "NULL",
        "stationname": "淮安收费站",
        "stationnumber": "GSLJS000038"
    },
    {
        "longitude": 119.4264507,
        "latitude": 31.4210467,
        "city": "NULL",
        "stationname": "溧阳西收费站",
        "stationnumber": "GSLJS000012"
    },
    {
        "longitude": 120.38252,
        "latitude": 31.5753,
        "city": "NULL",
        "stationname": "无锡东收费站",
        "stationnumber": "GSLJS000019"
    },
    {
        "longitude": 119.14706,
        "latitude": 34.48747,
        "city": "NULL",
        "stationname": "新坝收费站",
        "stationnumber": "GSLJS000058"
    },
    {
        "longitude": 121.40854,
        "latitude": 31.90868,
        "city": "NULL",
        "stationname": "悦来收费站",
        "stationnumber": "GSLJS000033"
    },
    {
        "longitude": 120.9649658,
        "latitude": 32.0483884,
        "city": "NULL",
        "stationname": "兴仁收费站",
        "stationnumber": "GSLJS000032"
    },
    {
        "longitude": 119.966749,
        "latitude": 31.806223,
        "city": "NULL",
        "stationname": "薛家收费站",
        "stationnumber": "GSLJS000018"
    },
    {
        "longitude": 119.27532,
        "latitude": 34.09966,
        "city": "NULL",
        "stationname": "灌南收费站",
        "stationnumber": "GSLJS000004"
    },
    {
        "longitude": 118.89537,
        "latitude": 32.06226,
        "city": "NULL",
        "stationname": "马群枢纽",
        "stationnumber": "GSLJS000055"
    },
    {
        "longitude": 119.3342995,
        "latitude": 34.7113695,
        "city": "NULL",
        "stationname": "港口高架桥",
        "stationnumber": "GSLJS000054"
    },
    {
        "longitude": 120.57201,
        "latitude": 31.02095,
        "city": "NULL",
        "stationname": "横扇收费站",
        "stationnumber": "GSLJS000035"
    },
    {
        "longitude": 121.70146,
        "latitude": 31.78333,
        "city": "NULL",
        "stationname": "启东东收费站",
        "stationnumber": "GSLJS000034"
    },
    {
        "longitude": 119.2223,
        "latitude": 34.29744,
        "city": "NULL",
        "stationname": "灌云西收费站",
        "stationnumber": "GSLJS000003"
    },
    {
        "longitude": 119.09095,
        "latitude": 34.59337,
        "city": "NULL",
        "stationname": "连云港西收费站",
        "stationnumber": "GSLJS000057"
    },
    {
        "longitude": 119.14469,
        "latitude": 34.95981,
        "city": "NULL",
        "stationname": "海头收费站",
        "stationnumber": "GSLJS000056"
    },
    {
        "longitude": 121.70146,
        "latitude": 31.78333,
        "city": "NULL",
        "stationname": "启东东收费站",
        "stationnumber": "GSLJS000034"
    },
    {
        "longitude": 120.55298,
        "latitude": 32.13387,
        "city": "NULL",
        "stationname": "如皋港收费站",
        "stationnumber": "GSLJS000031"
    },
    {
        "longitude": 118.99144,
        "latitude": 33.67211,
        "city": "NULL",
        "stationname": "丁集收费站",
        "stationnumber": "GSLJS000006"
    },
    {
        "longitude": 119.2149,
        "latitude": 33.79933,
        "city": "NULL",
        "stationname": "涟水收费站",
        "stationnumber": "GSLJS000005"
    },
    {
        "longitude": 117.88952,
        "latitude": 34.25831,
        "city": "NULL",
        "stationname": "邳州西收费站",
        "stationnumber": "GSLJS000051"
    },
    {
        "longitude": 120.31446,
        "latitude": 32.12077,
        "city": "NULL",
        "stationname": "季市收费站",
        "stationnumber": "GSLJS000030"
    },
    {
        "longitude": 119.09324,
        "latitude": 32.29987,
        "city": "NULL",
        "stationname": "十五里墩收费站",
        "stationnumber": "GSLJS000026"
    },
    {
        "longitude": 119.22181,
        "latitude": 34.60626,
        "city": "NULL",
        "stationname": "苍梧收费站",
        "stationnumber": "GSLJS000002"
    },
    {
        "longitude": 117.29091,
        "latitude": 34.17529,
        "city": "NULL",
        "stationname": "徐州收费站",
        "stationnumber": "GSLJS000050"
    },
    {
        "longitude": 118.79977,
        "latitude": 34.49298,
        "city": "NULL",
        "stationname": "东海收费站",
        "stationnumber": "GSLJS000053"
    },
    {
        "longitude": 118.64899,
        "latitude": 32.16777,
        "city": "NULL",
        "stationname": "花旗营收费站",
        "stationnumber": "GSLJS000025"
    },
    {
        "longitude": 118.851076,
        "latitude": 34.856981,
        "city": "NULL",
        "stationname": "班庄收费站",
        "stationnumber": "GSLJS000001"
    },
    {
        "longitude": 118.75297,
        "latitude": 32.97304,
        "city": "NULL",
        "stationname": "马坝西收费站",
        "stationnumber": "GSLJS000008"
    },
    {
        "longitude": 118.4233,
        "latitude": 34.31037,
        "city": "NULL",
        "stationname": "新沂南收费站",
        "stationnumber": "GSLJS000052"
    },
    {
        "longitude": 119.92555,
        "latitude": 32.34287,
        "city": "NULL",
        "stationname": "泰州收费站",
        "stationnumber": "GSLJS000028"
    },
    {
        "longitude": 119.62233,
        "latitude": 32.4098,
        "city": "NULL",
        "stationname": "砖桥收费站",
        "stationnumber": "GSLJS000027"
    },
    {
        "longitude": 118.90065,
        "latitude": 33.28927,
        "city": "NULL",
        "stationname": "洪泽收费站",
        "stationnumber": "GSLJS000007"
    },
    {
        "longitude": 120.9682,
        "latitude": 31.34412,
        "city": "NULL",
        "stationname": "昆山收费站",
        "stationnumber": "GSLJS000048"
    },
    {
        "longitude": 120.99138,
        "latitude": 31.95702,
        "city": "NULL",
        "stationname": "小海收费站",
        "stationnumber": "GSLJS000022"
    },
    {
        "longitude": 118.7542506,
        "latitude": 32.3113621,
        "city": "NULL",
        "stationname": "新集收费站",
        "stationnumber": "GSLJS000009"
    },
    {
        "longitude": 121.0634995,
        "latitude": 31.5890643,
        "city": "NULL",
        "stationname": "沙溪收费站",
        "stationnumber": "GSLJS000071"
    },
    {
        "longitude": 120.63676,
        "latitude": 31.35066,
        "city": "NULL",
        "stationname": "苏州收费站",
        "stationnumber": "GSLJS000047"
    },
    {
        "longitude": 121.06016,
        "latitude": 31.32986,
        "city": "NULL",
        "stationname": "陆家收费站",
        "stationnumber": "GSLJS000021"
    },
    {
        "longitude": 121.55111,
        "latitude": 31.89456,
        "city": "NULL",
        "stationname": "大生收费站",
        "stationnumber": "GSLJS000024"
    },
    {
        "longitude": 120.96253,
        "latitude": 31.71329,
        "city": "NULL",
        "stationname": "苏通大桥南收费站",
        "stationnumber": "GSLJS000070"
    },
    {
        "longitude": 117.16794,
        "latitude": 34.36908,
        "city": "NULL",
        "stationname": "柳新收费站",
        "stationnumber": "GSLJS000049"
    },
    {
        "longitude": 120.45281,
        "latitude": 32.07935,
        "city": "NULL",
        "stationname": "靖江收费站",
        "stationnumber": "GSLJS000044"
    },
    {
        "longitude": 121.166153,
        "latitude": 31.9300203,
        "city": "NULL",
        "stationname": "海门收费站",
        "stationnumber": "GSLJS000023"
    },
    {
        "longitude": 120.9649658,
        "latitude": 32.0483884,
        "city": "NULL",
        "stationname": "兴仁收费站",
        "stationnumber": "GSLJS000069"
    },
    {
        "longitude": 120.46395,
        "latitude": 32.76429,
        "city": "NULL",
        "stationname": "南沈灶收费站",
        "stationnumber": "GSLJS000066"
    },
    {
        "longitude": 120.01968,
        "latitude": 32.24005,
        "city": "NULL",
        "stationname": "泰兴北收费站",
        "stationnumber": "GSLJS000043"
    },
    {
        "longitude": 120.5659,
        "latitude": 31.35793,
        "city": "NULL",
        "stationname": "苏州新区收费站",
        "stationnumber": "GSLJS000020"
    },
    {
        "longitude": 120.01968,
        "latitude": 32.24005,
        "city": "NULL",
        "stationname": "泰兴北收费站",
        "stationnumber": "GSLJS000029"
    },
    {
        "longitude": 120.40899,
        "latitude": 32.9827,
        "city": "NULL",
        "stationname": "白驹收费站",
        "stationnumber": "GSLJS000065"
    },
    {
        "longitude": 120.3287,
        "latitude": 31.63166,
        "city": "NULL",
        "stationname": "无锡收费站",
        "stationnumber": "GSLJS000046"
    },
    {
        "longitude": 120.32632,
        "latitude": 31.76299,
        "city": "NULL",
        "stationname": "璜塘收费站",
        "stationnumber": "GSLJS000045"
    },
    {
        "longitude": 119.2775,
        "latitude": 32.03563,
        "city": "NULL",
        "stationname": "句容收费站",
        "stationnumber": "GSLJS000015"
    },
    {
        "longitude": 120.68251,
        "latitude": 32.34926,
        "city": "NULL",
        "stationname": "丁堰收费站",
        "stationnumber": "GSLJS000068"
    },
    {
        "longitude": 120.56705,
        "latitude": 32.52593,
        "city": "NULL",
        "stationname": "海安收费站",
        "stationnumber": "GSLJS000067"
    },
    {
        "longitude": 119.49529,
        "latitude": 32.80356,
        "city": "NULL",
        "stationname": "高邮收费站",
        "stationnumber": "GSLJS000040"
    },
    {
        "longitude": 118.60415,
        "latitude": 31.98613,
        "city": "NULL",
        "stationname": "南京长江第三大桥高速公路收费站",
        "stationnumber": "GSLJS000014"
    },
    {
        "longitude": 119.46064,
        "latitude": 32.06714,
        "city": "NULL",
        "stationname": "镇江收费站",
        "stationnumber": "GSLJS000017"
    },
    {
        "longitude": 119.92027,
        "latitude": 33.97226,
        "city": "NULL",
        "stationname": "蔡桥收费站",
        "stationnumber": "GSLJS000062"
    },
    {
        "longitude": 119.76286,
        "latitude": 34.10721,
        "city": "NULL",
        "stationname": "六套收费站",
        "stationnumber": "GSLJS000061"
    }
];
