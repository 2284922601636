<template>
                <div class="dialog" v-if="visible">
                    <div class="header">
                        <span>{{title ? title : '详情'}}</span>
                        <img class="close" :src="closeImage" alt="" @click="$emit('close')">
                    </div>
                    <div>
                        <slot />
                    </div>
                </div>
</template>

<script>
import closeImage from '@/assets/icons/close.png'
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
        }
    },
    data() {
        return {
            closeImage
        }
    }
}
</script>

<style lang="less" scoped>
.dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #fff;
  border-radius: 8px;
  z-index: 9999;
  .header {
  background-color: var(--theme-color);
  color: #fff;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
    min-width: 800px;


  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    height: 17px;
    cursor: pointer;
  }
}
}
</style>