export default [
    {
        "longitude": 119.956863,
        "latitude": 32.490525,
        "city": null,
        "stationname": "金汇通航泰州春兰直升机场",
        "stationnumber": "JCCJS000014"
    },
    {
        "longitude": 120.215404,
        "latitude": 33.427183,
        "city": null,
        "stationname": "盐城南洋国际机场",
        "stationnumber": "JCCJS000011"
    },
    {
        "longitude": 108.2,
        "latitude": 25.8,
        "city": null,
        "stationname": "南京吴圩机场",
        "stationnumber": "JCCJS000010"
    },
    {
        "longitude": 119.015288,
        "latitude": 33.61036,
        "city": null,
        "stationname": "淮安涟水机场",
        "stationnumber": "JCCJS000009"
    },
    {
        "longitude": 120.4,
        "latitude": 31.5,
        "city": null,
        "stationname": "无锡硕放机场",
        "stationnumber": "JCCJS000006"
    },
    {
        "longitude": 117.555282,
        "latitude": 34.059045,
        "city": null,
        "stationname": "徐州观音机场",
        "stationnumber": "JCCJS000005"
    },
    {
        "longitude": 120.6,
        "latitude": 31.3,
        "city": null,
        "stationname": "苏州机场",
        "stationnumber": "JCCJS000008"
    },
    {
        "longitude": 120.2,
        "latitude": 33.4,
        "city": null,
        "stationname": "江苏盐城南洋机场",
        "stationnumber": "JCCJS000007"
    },
    {
        "longitude": 118.881618,
        "latitude": 34.564462,
        "city": null,
        "stationname": "连云港白塔埠机场",
        "stationnumber": "JCCJS000002"
    },
    {
        "longitude": 119.784254,
        "latitude": 31.913092,
        "city": null,
        "stationname": "常州奔牛机场",
        "stationnumber": "JCCJS000001"
    },
    {
        "longitude": 120.980626,
        "latitude": 32.070845,
        "city": null,
        "stationname": "南通兴东机场",
        "stationnumber": "JCCJS000004"
    },
    {
        "longitude": 118.869687,
        "latitude": 31.732175,
        "city": null,
        "stationname": "南京禄口机场",
        "stationnumber": "JCCJS000003"
    },
    {
        "longitude": 121.701583,
        "latitude": 31.860161,
        "city": null,
        "stationname": "启东直升机场",
        "stationnumber": "JCCJS000013"
    },
    {
        "longitude": 120.426618,
        "latitude": 31.495853,
        "city": null,
        "stationname": "苏南硕放机场",
        "stationnumber": "JCCJS000012"
    }
];
